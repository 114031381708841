var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useAuthContext } from '../auth/useAuthContext';
import usePermission from '../hooks/usePermission';
import { findPermission } from '../sections/@dashboard/Permissions/utils';
var filterNavBarItem = function (arg, isSuperAdmin, user // Replace 'any' with the actual user type
) {
    return arg.filter(function (element) {
        var newElement = __assign({}, element);
        if (Array.isArray(newElement.children)) {
            newElement.children = filterNavBarItem(newElement.children, isSuperAdmin, user);
        }
        var showItem = false;
        if (Array.isArray(newElement.permission)) {
            newElement.permission.forEach(function (elt) {
                showItem = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, elt.model, elt.method);
            });
        }
        else {
            showItem = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, newElement.permission.model, newElement.permission.method);
        }
        return isSuperAdmin || showItem;
    });
};
export var useFilterNavBarItem = function (arg) {
    var isSuperAdmin = usePermission().isSuperAdmin;
    var user = useAuthContext().user;
    var newArg = arg.map(function (elt) { return (__assign(__assign({}, elt), { items: filterNavBarItem(elt.items, isSuperAdmin, user) })); });
    return newArg;
};
