// ----------------------------------------------------------------------
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export default function flattenArray(list, key) {
    if (key === void 0) { key = 'children'; }
    var children = [];
    var flatten = list === null || list === void 0 ? void 0 : list.map(function (item) {
        if (item[key] && item[key].length) {
            children = __spreadArray(__spreadArray([], children, true), item[key], true);
        }
        return item;
    });
    return flatten === null || flatten === void 0 ? void 0 : flatten.concat(children.length ? flattenArray(children, key) : children);
}
